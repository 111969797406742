import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%"  viewBox="0 0 154.000000 100.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd"  transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)">


<path d="M508 733 c68 -109 98 -152 103 -153 6 0 119 166 119 175 0 3 -54 5
-120 5 l-120 0 18 -27z"/>
<path d="M768 733 c81 -129 98 -153 102 -153 6 0 120 166 120 174 0 3 -54 6
-120 6 l-120 0 18 -27z"/>
<path d="M370 565 c0 -6 97 -153 110 -169 7 -7 103 123 116 159 5 13 -11 15
-110 15 -64 0 -116 -2 -116 -5z"/>
<path d="M636 558 c29 -50 106 -158 112 -158 6 0 102 140 110 161 2 5 -49 9
-113 9 -90 0 -115 -3 -109 -12z"/>
<path d="M880 563 c1 -5 25 -44 55 -88 l55 -80 -116 -3 c-86 -2 -115 -6 -111
-15 3 -7 29 -50 60 -96 l55 -83 61 90 c34 50 61 94 61 97 0 3 27 46 60 95 l61
90 -121 0 c-66 0 -120 -3 -120 -7z"/>
<path d="M500 384 c0 -11 111 -174 119 -174 11 0 111 152 111 168 0 9 -33 12
-115 12 -63 0 -115 -2 -115 -6z"/>
</g>
</svg>




    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
